<template>
	<div style="height: 100%;">
		<main-table tool>
			<div slot="table" class="df f1 fdc">
				<div class="df fww">
					<div class="p10 mr20 mb20 br5 miw100 cp" :style="'box-shadow: 0 0 10px 5px #f1f1f1; background-color: ' + (status.checked ? '#b7a06a' : '#e2d6ba')" v-for="(status, s) in status_tabs" :key="s" @click="toggleStatus(s)">
						<div class="df jcsb mb5">
							<div class="fs16 fw6 c3">{{status.count || 0}}</div>
						</div>
						<div class="fs14 c6">{{status.name}}</div>
					</div>
					<div class="mb10">
						<el-button type="primary" size="mini" @click="show_fillter = !show_fillter">{{show_fillter ? '收起' : '筛选'}}</el-button>
					</div>
				</div>
				<el-form :inline="true" :model="params" :size="theme.size" v-if="show_fillter">
					<el-form-item >
						<el-input v-model="params.keyword" placeholder="请输入流水/订单/合同/购销方" clearable></el-input>
					</el-form-item>
					<el-form-item v-if="common_types.length > 1">
						<el-select v-model="params.common_type" placeholder="关联" clearable filterable>
							<el-option v-for="(tpye, t) in common_types" :key="t" :label="tpye" :value="t"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select v-model="params.employees" placeholder="请选择申请人" filterable  multiple collapse-tags>
							<el-option v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select v-model="params.sellers" placeholder="请选择销售企业" filterable  multiple collapse-tags>
							<el-option v-for="company in companies" :key="company.id" :label="company.name" :value="company.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select v-model="params.type" placeholder="类型" clearable filterable>
							<el-option v-for="(type, t) in types" :key="t" :label="type" :value="t"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="getInvoices({ ...params, page: 1})">查询</el-button>
						<el-button type="primary" @click="showInvoice({type: 1, common_type: 'App\\Models\\Order'})">申请</el-button>
					</el-form-item>
				</el-form>
				<div class="f1 pr">
					<el-table ref="wallet_table" class="bill scroll-wrapper pa" height="100%" :data="invoices" :size="theme.size">
						<el-table-column label="申请人" min-width="90">
							<template slot-scope="scope">
								<p>{{scope.row.employee.name}}</p>
								<p v-if="oa_users[scope.row.employee_id] && oa_users[scope.row.employee_id].main_department && oa_departments[oa_users[scope.row.employee_id].main_department]">
									{{oa_departments[oa_users[scope.row.employee_id].main_department].name}}
								</p>
								<p v-else-if="scope.row.employee.shop">{{scope.row.employee.shop.name}}</p>
							</template>
						</el-table-column>
						<el-table-column label="流水" min-width="200">
							<template slot-scope="scope">
								<el-link type="primary" :disabled="!$utils.update($api.URI_INVOICES)" @click="showInvoice({...scope.row, common_no: scope.row.common ? scope.row.common.no : ''})">{{scope.row.no}}</el-link>
								<p v-if="scope.row.common">{{common_types[scope.row.common_type]}} {{scope.row.common.no}}</p>
							</template>
						</el-table-column>
						<el-table-column label="购销单位" min-width="220">
							<template slot-scope="scope">
								<p>销方: {{scope.row.seller.name}}</p>
								<p>购方: {{scope.row.name}}</p>
							</template>
						</el-table-column>
						<el-table-column label="金额" min-width="80" :formatter="({amount}) => {  return amount/100 + ' 元'; }"></el-table-column>
						<el-table-column label="发票类型" min-width="80" :formatter="({type}) => {  return types[type]; }"></el-table-column>
						<el-table-column label="申请状态" min-width="80">
							<template slot-scope="scope">
								<el-tooltip effect="dark" placement="top" :content="'OA审核编号'+scope.row.approval.sp_no" v-if="scope.row.approval">
									<el-link type="primary" @click="$refs['invoice_show'].onStatus(scope.row)">{{statuses[scope.row.status] || '未知'}}</el-link>
								</el-tooltip>
								<el-link type="primary" @click="$refs['invoice_show'].onStatus(scope.row)" v-else>{{statuses[scope.row.status] || '未知'}}</el-link>
							</template>
						</el-table-column>
						<el-table-column label="备注说明" prop="remarks" min-width="160"></el-table-column>
						<el-table-column label="操作时间" width="210" v-if="theme.width >= 1240">
							<template slot-scope="scope">
								<p>更新：{{scope.row.updated_at}}</p>
								<p>创建：{{scope.row.created_at}}</p>
							</template>
						</el-table-column>
						<el-table-column width="80">
							<el-button slot="header" type="primary" size="mini" @click="showInvoice({common_type: 'App\\Models\\Order'})">申请</el-button>
							<el-button slot-scope="scope" type="text" :disabled="!$utils.delete($api.URI_INVOICES)" @click="$refs['invoice_show'].onRestore(scope.row)">删除</el-button>
						</el-table-column>
					</el-table>
				</div>
			</div>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getInvoices({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getInvoices({ ...params, page: v }) }"
				background>	
			</el-pagination>
		</main-table>
		<invoice-show ref="invoice_show" :types="types" :models="common_types" :companies="companies" :status="statuses" @refresh="getInvoices(params)"></invoice-show>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import mainTable from '@/components/main-table';
	import invoiceShow from './show';

	export default {
		components: {
			mainTable,
			invoiceShow
		},
		computed: {
			...mapState(['theme', 'models', 'oa_users', 'oa_departments']),
			invoice_dict () {
				if (!this.models) return {};
				return this.models.invoices || {};
			},
			companies () {
				return this.invoice_dict.companies || [];
			},
			statuses () {
				return this.invoice_dict.statuses || {};
			},
			types () {
				return this.invoice_dict.types || {};
			}
		},
		methods: {
			showInvoice (r) {
				this.$refs['invoice_show'].open(r);
				this.invoice = { ...r };
				this.invoice_dialog = true;
			},
			toggleStatus (s) {
				const { status } = this.params
				const index = status.indexOf(s);
				if (index > -1) {
					status.splice(index, 1);
					this.status_tabs[s].checked = false;
				} else {
					status.push(s);
					this.status_tabs[s].checked = true;
				}
				this.getInvoices(this.params);
			},
			async getInvoices (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_INVOICES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.invoices = result.data;
				this.employees = result.employees;
				this.common_types = result.common_types;
				if (result.counts) {
					var status_tabs = {};
					const status = params.status || [];
					for (var s in this.statuses) {
						status_tabs[s] = {
							name: this.statuses[s],
							count: result.counts[s] || 0,
							checked: status.indexOf(s) >= 0 ? true : false
						};
					}
					this.status_tabs = status_tabs;
				}
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				invoices: [],
				employees: [],
				status_tabs: [],
				common_types: {},
				show_fillter: false,
				invoice_dialog: false,
				invoice: {},
				params: {
					status: [],
					perPage: 10,
				}
			}
		},
		created () {
			this.getInvoices(this.params, true);
		}
	};
</script>
